export const Tempus = () => ({
  showIssue() {
    return (this.issueSelected.id && true) || false
  },

  getIssue(key) {
    if (!this.issueSelectedData.hasOwnProperty("key")) {
      return ""
    }
    switch (key) {
      case "key":
        return this.issueSelectedData.key
      case "summary":
        return this.issueSelectedData.fields.summary
      case "timespent":
        return this.issueSelectedData.fields.timespent
      case "labels_length":
        return this.issueSelectedData.fields.labels.length
      case "labels":
        return this.issueSelectedData.fields.labels
      case "status_name":
        return this.issueSelectedData.fields.status.name
      default:
        return ""
    }
  },

  itemInActiveList(type, key) {
    return (
      this.itemlistsActive[type] && this.itemlistsActive[type].includes(key)
    )
  },

  getIssueSearchstring(issue) {
    let issueSearch = issue.fields.labels.join(", ")
    issueSearch += ", " + issue.fields.status.name
    if ("parent" in issue.fields) {
      issueSearch += ", " + issue.fields.parent.key
    }
    return issueSearch
  },

  focusCombobox(event, type) {
    const inputElement = document.getElementById("combobox-" + type)
    if (event.type == "focus" || event.type == "click") {
      // empty input field
      inputElement.value = ""
      // close is false
      return false
    } else if (
      document.activeElement != event.target &&
      event.type == "keyup" &&
      (event.code == "Tab" || event.code == "Enter")
    ) {
      return true
    }
    // on change
    if (event.type == "keyup" && event.code != "Tab") {
      const query = inputElement.value
      const itemlist = this.itemlists[type]
      const result =
        query === ""
          ? itemlist
          : itemlist.filter((option) => {
              const haystack = document.getElementById(option).dataset.name
              return haystack.toLowerCase().includes(query.toLowerCase())
            })
      this.itemlistsActive[type] = result
      // not finished yet
      if (event.code != "Enter" && event.code != "ArrowDown") {
        return false
        // finished. fetch project
      } else if (event.code == "Enter" && result.length == 1) {
        this.comboboxProxy(type, result[0])
        // close
        return true
        // switch to arrows, go in list
      } else if (event.code == "ArrowDown" && result.length > 0) {
        const element = document.getElementById(result[0])
        element.focus()
        // not closed
        return false
      }
    }
  },

  comboboxProxy(type, key) {
    if (type === "projects") {
      this.setProject(key)
    } else if (type === "issues") {
      this.setIssue(key)
    } else if (type === "timesheets") {
      this.setTimesheet(key)
    } else if (type === "users") {
      this.setUserSelected(key)
    } else if (type === "customerOptions") {
      this.setCustomerSelected(key)
    }
    return true
  },

  toggleSelectedWorklogsForCharge() {
    const checkboxes = document.querySelectorAll(
      'input[name="worklogToCharge[]"]',
    )
    checkboxes.forEach((chbx) => {
      chbx.checked = this.toggleCheckboxesForCharge
    })
    this.toggleCheckboxesForCharge = !this.toggleCheckboxesForCharge
  },

  copyCsv(issues) {
    const csvRows = []
    const issuesToCopy = JSON.parse(JSON.stringify(issues))
    issuesToCopy.sort((a, b) => {
      if (!("parent" in a.fields)) {
        return -1
      } else if (!("parent" in b.fields)) {
        return 1
      }
      return (a.fields.parent.key > b.fields.parent.key && -1) || 1
    })
    issuesToCopy.forEach((issue) => {
      const worklogs = issue.worklogs
      let parentKey = "ohne Epic"
      let parentSummary = "-"
      let parentType = "-"
      if ("parent" in issue.fields) {
        parentKey = issue.fields.parent.key
        parentSummary = issue.fields.parent.fields.summary
        parentType = issue.fields.parent.fields.issuetype.name
      }
      // const issueWorklogSummary = this.calculateWorklogSummary(worklogs)
      worklogs.forEach((worklog) => {
        const row = [
          parentKey,
          parentSummary,
          parentType,
          issue.key,
          issue.fields.summary,
          this.getWorklogComment(worklog),
          worklog.author.displayName,
          this.formatDateOnly(worklog.started),
          this.formatTimeOnly(worklog.started),
          worklog.timeSpentSeconds / 60,
          this.getWorklogProperty(worklog, "charged", 0) > 0
            ? "abgerechnet"
            : "nicht abgerechnet",
        ]
        csvRows.push(row)
      })
    })
    const csvData = csvRows.map((row) => row.join("\t")).join("\n")
    const copyField = document.querySelector("#exportCsv")
    copyField.value = csvData
    navigator.clipboard.writeText(copyField.value)
  },
})
