export const TempusIssue = () => ({
  comboboxType: "issues",
  nextElementId: "comment-active-tracking",
  close: true,
  viewOnlyMyIssues: Alpine.$persist(true),
  viewNoDoneIssues: Alpine.$persist(true),

  initIssue() {},

  fetchIssueData(id_or_key) {
    this.fetchGETSomething("/issue/" + id_or_key)
      .then((result) => {
        if (result.err) {
          window.location.reload()
        }
        this.issueSelected = result
        this.issueSelectedData = result
        if (
          this.projectSelected.id == null ||
          this.projectSelected.key != result.fields.project.key
        ) {
          this.setProject(result.fields.project.key)
        }
      })
      .catch((err) => console.error(err))
  },

  setIssue(issueKey) {
    if (
      this.activeWorklog !== null &&
      this.activeWorklog.issueKey != issueKey
    ) {
      this.setTrackingStatus()
    }
    if (this.issues.hasOwnProperty(issueKey)) {
      this.issueSelected = this.issues[issueKey]
      this.fetchIssueData(this.issueSelected.id, true)
    } else {
      this.fetchIssueData(issueKey)
      this.issueSelected = this.issueSelectedData
    }
    // needed?
    // this.setProject(this.issueSelectedData.fields.project.key)

    if (document.querySelector("#" + issueKey)) {
      document.querySelector("#combobox-issues").value = document.querySelector(
        "#" + issueKey,
      ).dataset.name
    } else {
      document.querySelector("#combobox-issues").value = null
      document.querySelector("#combobox-projects").value = null
    }
    return true
  },

  fetchIssues(id, issues = []) {
    const max = 100
    let total = 0
    if (!id !== null) {
      let query =
        "&fields=summary,issuetype,parent,labels,status&maxResults=" +
        max +
        "&startAt=" +
        issues.length +
        "&jql=" +
        encodeURIComponent("project = " + id)
      if (this.viewNoDoneIssues) {
        query += encodeURIComponent(" AND resolution = Unresolved")
      }
      if (this.viewOnlyMyIssues) {
        query += encodeURIComponent(
          ' AND assignee = "' + this.user.displayName + '"',
        )
      }
      query += encodeURIComponent(" AND type != epic")
      query += encodeURIComponent(" order by updated ASC")

      this.fetchGETSomething("/search", query)
        .then((result) => {
          if (total == 0) {
            total = result.total
          }
          issues.push(...result.issues)
          if (result.total > issues.length) {
            return this.fetchIssues(id, issues)
          } else {
            return issues
          }
        })
        .then((issues) => {
          if (issues) {
            issues.sort((a, b) => {
              if (!("parent" in a.fields)) {
                return -1
              } else if (!("parent" in b.fields)) {
                return 1
              }
              return (a.fields.parent.key > b.fields.parent.key && -1) || 1
            })
            this.issues = {}
            this.itemlists["issues"] = []
            for (const issue of issues) {
              this.issues[issue.key] = issue
              this.itemlists["issues"].push(issue.key)
            }
            this.itemlistsActive["issues"] = this.itemlists["issues"]
          }
        })
        .catch((err) => console.error(err))
    }
  },
  toggleViewOnlyMyIssues() {
    this.viewOnlyMyIssues = !this.viewOnlyMyIssues
    if (this.projectSelected) {
      this.fetchIssues(this.projectSelected.id)
    }
  },
  toggleViewNoDoneIssues() {
    this.viewNoDoneIssues = !this.viewNoDoneIssues
    if (this.projectSelected) {
      this.fetchIssues(this.projectSelected.id)
    }
  },
})
